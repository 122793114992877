<template>
  <div class="head-container">
    <!-- 搜索 -->
    <el-select
            v-model="query.status"
            clearable
            placeholder="状态"
            class="filter-item"
            style="width: 130px"
            @change="toQuery"
    >
      <el-option v-for="item in busSecStatusOptions"
                 :key="item.key"
                 :label="item.display_name"
                 :value="item.key"/>
    </el-select>
    <el-input
            v-model="query.sectorCode"
            clearable
            placeholder="板块编码"
            style="width: 200px;"
            class="filter-item"
            @keyup.enter.native="toQuery"
    />
    <el-input
            v-model="query.sectorName"
            clearable
            placeholder="板块名称"
            style="width: 200px;"
            class="filter-item"
            @keyup.enter.native="toQuery"
    />

    <el-date-picker
      class="filter-item"
      v-model="query.createTime"
      type="date"
      placeholder="日期">
    </el-date-picker>
    <el-button
            class="filter-item"
            size="mini"
            type="primary"
            icon="el-icon-search"
            @click="toQuery"
    >搜索</el-button>
    <!-- 新增 -->
    <div style="display: inline-block;margin: 0px 2px;">
      <el-button
        v-if="checkPermission(['ADMIN','SECTOR_ALL','SECTOR_CREATE'])"
        class="filter-item"
        size="mini"
        type="primary"
        icon="el-icon-plus"
        @click="$refs.form.dialog = true">新增</el-button>
      <eForm ref="form" :is-add="true"/>
    </div>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission' // 权限判断函数
import eForm from './form'
import { mapGetters } from 'vuex'
export default {
  components: { eForm },
  props: {
    query: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters([
      'busSecStatusOptions'
    ])
  },
  data() {
    return {
    }
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$emit('toQuery');
    }
  }
}
</script>
