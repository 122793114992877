<template>
  <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isAdd ? '新增板块' : '编辑板块'" width="500px">
    <el-form ref="form" :model="form" :rules="rules" size="small" label-width="80px">
      <el-form-item label="板块编码" prop="sectorCode">
        <el-input v-model="form.sectorCode" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="板块名称" prop="sectorName">
        <el-input v-model="form.sectorName" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="板块图标" prop="sectorIcon">
        <el-input v-model="form.sectorIcon" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="板块简介" prop="sectorDesc">
        <el-input v-model="form.sectorDesc" style="width: 370px;"/>
      </el-form-item>
      <el-form-item label="板块状态" prop="status">
        <el-radio-group v-model="form.status">
           <el-radio :label="item.key" :key="item.key" v-for="item in busSecStatusOptions">{{item.display_name}}</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="text" @click="cancel">取消</el-button>
      <el-button :loading="loading" type="primary" @click="doSubmit">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { add, edit } from '@/api/bussetup/tBusinessSector'
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    }
  },
   computed: {
    ...mapGetters([
      'busSecStatusOptions'
    ])
  },
  data() {
    return {
      loading: false, dialog: false,
      form: {
        id: '',
        sectorCode: '',
        sectorName: '',
        sectorIcon: '',
        sectorDesc: '',
        status: 0
      },
      rules: {
        sectorName: [
          { required: true, message: '请输入板块名称', trigger: 'blur' },
          { min: 3, max: 20, message: '长度在 3 到 20 个字符(10个汉字)', trigger: 'blur' }
        ],
        sectorCode: [
          { required: true, message: '请输入板块编码', trigger: 'blur' },
          { min: 3, max: 20, message: '长度在 3 到 20 个字符(10个汉字)', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '状态不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    cancel() {
      this.resetForm()
    },
    doSubmit() {
       this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.isAdd) {
            this.doAdd()
          } else this.doEdit()
        }else {
          return false
        }
      })
    },
    doAdd() {
      add(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '添加成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.$parent.$parent.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    doEdit() {
      edit(this.form).then(res => {
        this.resetForm()
        this.$notify({
          title: '修改成功',
          type: 'success',
          duration: 2500
        })
        this.loading = false
        this.sup_this.init()
      }).catch(err => {
        this.loading = false
        console.log(err.response.data.message)
      })
    },
    resetForm() {
      this.dialog = false
      this.$refs['form'].resetFields()
      this.form = {
        id: '',
        sectorCode: '',
        sectorName: '',
        sectorIcon: '',
        sectorDesc: '',
        status: 0
      }
    }
  }
}
</script>

<style scoped>

</style>
